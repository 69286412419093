import React, { useState } from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Button from '../components/atoms/inputs/Button';
import JoinTheSocialSidekickModal from '../components/organisms/JoinTheSocialSidekickModal';

const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'experiences',
    'mobile app',
    'events',
    'ai agent',
    'flockx',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'flockx',
  'ai agent',
  'ai',
  'multi-agent system',
  'multi agent',
  'ai agent collaboration',
  'ai agent management'
];

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
    heroImageDesktop,
    heroImageMobile,
    sponsorBannerDesktop,
    sponsorBannerMobile,
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };
  const [showSocialSidekickModal, setShowSocialSidekickModal] = useState(false);
  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass=" "
    >

      <SEO
        title={title}
        keywords={SEO_KEYWORDS}
        description={description}
        schemaMarkup={schemaMarkup}
      />

      <div className="relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-32">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-regular text-gray-700 sm:text-6xl">
            Upgrade your Life with the Ultimate  <span className="text-primary">Social Sidekick</span>
            </h1>
            <p className="mt-6 text-lg leading-8 font-regular text-gray-600 max-w-lg">
            Get real-time feedback and personalized advice from the communities you love. From favorite local hangouts to community third places. it's yours to own and evolves with your social journey.
            </p>

            <div className="mt-10 flex items-center gap-x-6">
              <div
              >
                <Button
                  label="Join the Waitlist"
                  onClickHandler={() => setShowSocialSidekickModal(true)}
                  id="request-beta-access-button"
                />
              </div>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
              <title>Social Sidekick Showcase</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src="https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1713797593/flockx-website/Landing%20page/social-sidekick_qb7tri.png" alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
      <JoinTheSocialSidekickModal
        show={showSocialSidekickModal}
        onHide={() => setShowSocialSidekickModal(false)}
      />
    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
    heroImageDesktop: file(name: { eq: "landing-desktop-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroImageMobile: file(name: { eq: "landing-mobile-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sponsorBannerDesktop: file(name: { eq: "sponsors-banner" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sponsorBannerMobile: file(name: { eq: "sponsors-banner-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
